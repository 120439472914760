<template>
  <div class="my-3 mx-4">
    <div class="row">
      <div class="col-12">
        <div class="heading">
          Reservation Edit - Primary Occupant / Occupancy and Equipment Details
        </div>
        <br />
        <AdminReservationContact
          header="Reservation Customer"
          :contact="customer"
        />
        <AdminReservationInfo :showCheckboxes="true" />
        <ValidationObserver ref="editReservationForm">
          <form @submit.prevent="onSubmit">
            <FormErrorAlert
              v-if="errors.length > 0"
              :errors="errors"
              :formRef="this.$refs.editReservationForm"
            />
            <div class="row">
              <div class="col-lg-6">
                <div class="card">
                  <div class="card-header">
                    <div class="card-title mt-2 mb-0">Primary Occupant</div>
                  </div>
                  <div class="card-body">
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="required|customerName|max:30"
                          name="First Name"
                          id="firstName"
                          placeholder="Enter your first name"
                          v-model="reservation.primaryOccupant.firstName"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="required|customerName|max:30"
                          name="Last Name"
                          id="lastName"
                          placeholder="Enter your last name"
                          v-model="reservation.primaryOccupant.lastName"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="required|address|max:70"
                          name="Address 1"
                          id="street1"
                          placeholder="Enter your street address"
                          v-model="reservation.primaryOccupant.street1"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="address|max:70"
                          name="Address 2"
                          id="street2"
                          placeholder="Enter your street address, cont."
                          v-model="reservation.primaryOccupant.street2"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="required|city|max:58"
                          name="City"
                          id="city"
                          placeholder="Enter your city"
                          v-model="reservation.primaryOccupant.city"
                        />
                      </div>
                    </div>
                    <div
                      class="form-row justify-content-center"
                      v-if="isForeignAddress"
                    >
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          class="mb-2"
                          rules="required|city|max:20"
                          name="State"
                          id="state"
                          placeholder="Enter your state"
                          v-model="reservation.primaryOccupant.state"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center" v-else>
                      <div class="col col-12 form-group text-left">
                        <ValidationProvider
                          rules="required"
                          name="State"
                          v-slot="{ errors, ariaInput, ariaMsg }"
                        >
                          <label
                            @click="$refs.select.focus()"
                            :class="{ error: errors[0] }"
                            for="state"
                          >
                            State
                            <span class="error">*</span>
                          </label>
                          <select
                            class="form-control form-control-lg"
                            id="state"
                            v-model="reservation.primaryOccupant.state"
                            v-bind="ariaInput"
                          >
                            <option
                              v-for="(state, index) in states"
                              :key="index"
                              :value="state.abbreviation"
                            >
                              {{ state.name }}
                            </option>
                          </select>
                          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                            <li
                              v-for="(error, index) in errors"
                              :key="index"
                              class="error"
                              v-bind="ariaMsg"
                            >
                              {{ error }}
                            </li>
                          </ul>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          :rules="
                            isForeignAddress
                              ? 'required'
                              : 'required|min:5|zipCode'
                          "
                          name="Zip Code"
                          id="zipCode"
                          placeholder="Enter your zip code"
                          v-model="reservation.primaryOccupant.zipcode"
                          :mask="isForeignAddress ? '' : '#####-####'"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          type="email"
                          rules="required|email|max:256"
                          name="Email"
                          id="email"
                          placeholder="Enter your email address"
                          v-model="reservation.primaryOccupant.email"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="required|maskNumMin:10"
                          name="Phone"
                          id="phone"
                          placeholder="Enter your phone number"
                          v-model="reservation.primaryOccupant.mobilePhone"
                          mask="(###) ###-####"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="maskNumMin:10"
                          name="Alternate Phone"
                          id="alternatePhone"
                          placeholder="Enter your alternate phone number"
                          v-model="reservation.primaryOccupant.homePhone"
                          mask="(###) ###-####"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title mt-2 mb-0">Occupancy Information</h4>
                  </div>
                  <div class="card-body">
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          :rules="
                            `required|numeric|${
                              checkPermission(
                                'ReservationManagementOverrideOnModify'
                              )
                                ? 'between:1,10000'
                                : 'between:' + basePeople + ',' + maxPeople
                            }`
                          "
                          name="Number of People"
                          id="numberOfPeople"
                          placeholder="Enter number of people"
                          v-model="reservation.numberOfAdults"
                          @change="
                            fieldChange({
                              maxAmountProp: 'maxPeople',
                              propName: 'numberOfAdults',
                              fieldName: 'Number of People'
                            })
                          "
                        />
                        <p class="pl-1" v-if="basePeople || maxPeople">
                          ({{ basePeople ? "Min:" + basePeople + " " : ""
                          }}{{ maxPeople ? "Max: " + maxPeople : "" }})
                        </p>
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          :rules="
                            `required|numeric|${
                              checkPermission(
                                'ReservationManagementOverrideOnModify'
                              )
                                ? 'between:0,500'
                                : 'between:0,' + maxVehicles
                            }`
                          "
                          name="Number of Vehicles"
                          id="numberOfVehicles"
                          placeholder="Enter number of vehicles"
                          v-model="reservation.numberOfVehicles"
                          @change="
                            fieldChange({
                              maxAmountProp: 'maxVehicles',
                              propName: 'numberOfVehicles',
                              fieldName: 'Number of Vehicles'
                            })
                          "
                        />
                        <p class="pl-1" v-if="maxVehicles">
                          ({{
                            maxVehicles ? "Max. Total: " + maxVehicles : ""
                          }})
                        </p>
                      </div>
                    </div>
                    <div
                      v-for="discount in discountClasses"
                      :key="discount.id"
                      class="form-row justify-content-center"
                    >
                      <div class="col col-12 form-group text-left">
                        <label>
                          <input
                            type="checkbox"
                            :value="discount.id"
                            :id="`discount_class_${discount.id}`"
                            v-model="reservation.discounts"
                          />
                          {{ discount.name }}
                        </label>
                        <p v-html="discount.description"></p>
                      </div>
                    </div>
                    <p class="text-muted m-0">
                      Special rate/discount may be eligible for pass holders,
                      special group and membership holders. Proof of
                      age/membership may be required at the time of check in.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title mt-2 mb-0">Equipment Information</h4>
                  </div>
                  <div class="card-body">
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <ValidationProvider
                          rules="required"
                          name="Equipment Type"
                          v-slot="{ errors, ariaInput, ariaMsg }"
                        >
                          <label
                            @click="$refs.select.focus()"
                            :class="{ error: errors[0] }"
                            for="equipmentType"
                          >
                            Equipment Type
                            <span class="error">*</span>
                          </label>
                          <select
                            class="form-control form-control-lg"
                            id="equipmentType"
                            v-model="reservation.equipmentType"
                            v-bind="ariaInput"
                          >
                            <option
                              v-for="spotEquipmentType in spotEquipmentTypes"
                              :key="spotEquipmentType.equipmentType.id"
                              :value="spotEquipmentType.equipmentType.name"
                            >
                              {{ spotEquipmentType.equipmentType.name }}
                            </option>
                          </select>
                          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                            <li
                              v-for="(error, index) in errors"
                              :key="index"
                              class="error"
                              v-bind="ariaMsg"
                            >
                              {{ error }}
                            </li>
                          </ul>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div
                      class="form-row justify-content-center"
                      v-if="maxVehicleLength"
                    >
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          :rules="
                            `${
                              this.reservation.equipmentType !== 'None'
                                ? 'required'
                                : ''
                            }|numeric|${
                              maxVehicleLength
                                ? 'between:0,' + maxVehicleLength
                                : ''
                            }`
                          "
                          name="Equipment Length (ft)"
                          id="equipmentLength"
                          placeholder="Enter length of equipment"
                          v-model="reservation.equipmentLength"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p><span style="color: red">*</span> Indicates a required field</p>
            <button
              type="submit"
              class="btn btn-lg btn-primary mr-2 float-left"
            >
              Continue Edit
            </button>
            <button
              type="button"
              @click="goBack"
              class="btn btn-lg btn-secondary mr-2 float-left"
            >
              Back
            </button>
            <button
              type="button"
              @click="goHome"
              class="btn btn-cancel btn-lg mr-2 float-left"
            >
              Cancel
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <AdminOverrideAlertModal
      @override="overrideBusinessRule"
      @cancel="goBack"
      header="Do you wish to override this business rule?"
    />
    <b-modal
      id="confirmDiscountModal"
      title="Residency Required"
      no-stacking
      no-close-on-backdrop
      hide-header-close
      no-close-on-esc
    >
      <p class="my-4">
        Special Discounted rates are applicable for Illinois residents only.
        Based on your customer, customer is not eligible for this discount. If
        you think customer should be eligible, then update customer profile for
        the IL address under customer profile. Customer Identification card with
        Address is required at check-in.
      </p>
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn" variant="primary" @click="discardAndGoToProfile"
          >Discard Reservation and Go to Profile</b-button
        >
        <b-button
          class="btn"
          variant="secondary"
          @click="continueReservation(ok)"
          >Continue With Reservation</b-button
        >
      </template>
    </b-modal>
    <AdminOverrideAlertModal
      header="Do you wish to override this field?"
      modalRef="override-field-modal"
      :overriddenProp="overriddenField"
      @cancel="overrideCancel"
    />
  </div>
</template>

<script>
import AdminReservationContact from "@/components/admin/AdminReservationContact.vue";
import AdminReservationInfo from "@/components/admin/AdminReservationInfo.vue";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TextInput from "@/validation/TextInput.vue";
import AdminProfileService from "@/services/admin/AdminProfileService.js";
import TenantService from "@/services/TenantService.js";
import PricingService from "@/services/PricingService.js";
import states from "@/store/modules/states.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import AdminOverrideAlertModal from "@/components/admin/AdminOverrideAlertModal.vue";

export default {
  name: "AdminReservationEditOccupant",
  title: "Admin - Reservation Edit Occupant",
  mixins: [checkPermissionMixin],
  components: {
    AdminReservationContact,
    AdminReservationInfo,
    ValidationObserver,
    ValidationProvider,
    TextInput,
    FormErrorAlert,
    AdminOverrideAlertModal
  },
  data() {
    return {
      errors: {},
      customer: null,
      discountClasses: [],
      discountAlertShown: false,
      overriddenField: null,
      currentReservation: null
    };
  },
  methods: {
    goHome() {
      this.$store.dispatch("transaction/clear");
      this.$router.push("/admin").catch(() => {});
    },
    goBack() {
      this.$router
        .push("/admin/reservation-edit-spot/" + this.reservation.reservationId)
        .catch(() => {});
    },
    overrideBusinessRule() {
      this.$store.dispatch("alert/clear");
      this.reservation.overrideBusinessRule = true;
    },
    onSubmit() {
      this.$refs.editReservationForm.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.editReservationForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.editReservationForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          this.verifyCustomerQualitiesForDiscounts().then(verified => {
            if (!verified && !this.discountAlertShown) {
              //Show modal
              this.$bvModal.show("confirmDiscountModal");
              this.discountAlertShown = true;
              return;
            }
            try {
              this.$store.commit("auth/setLoading", true);
              this.reservation.phone = this.reservation.primaryOccupant.mobilePhone;
              this.reservation.alternatePhone = this.reservation.primaryOccupant.homePhone;
              this.reservation.zipCode = this.reservation.primaryOccupant.zipcode;
              this.$store.dispatch(
                "transaction/setReservation",
                this.reservation
              );
            } catch (err) {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "public"
              });
            } finally {
              this.$store.commit("auth/setLoading", false);
              this.$router
                .push("/admin/reservation-edit-review")
                .catch(() => {});
            }
          });
        }
      });
    },
    discardAndGoToProfile() {
      //Discard reservation
      const spotReservationService = new AdminSpotReservationService(
        this.tenantId
      );
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      spotReservationService
        .releaseSpotClaim(claimData.spotId, claimData.lockCode)
        .then(response => {
          if (response.statusCode === "Success") {
            this.$store.dispatch("transaction/clearReservation");
          }
        });

      //Redirect to customer profile
      const customerId = this.reservation.customerId;
      this.$store.dispatch("transaction/clear");
      this.$router.push("/admin/customer-edit/" + customerId).catch(() => {});
    },
    continueReservation(ok) {
      ok();
      this.reservation.discounts = [];
      this.onSubmit();
    },
    async verifyCustomerQualitiesForDiscounts() {
      //Pass discounts to backend to verify which have residency requirement and if they've been met
      let customerDiscounts = {
        discounts: [...this.reservation.discounts]
      };
      if (customerDiscounts.discounts.length == 0) return true;

      let profileService = new AdminProfileService(this.tenantId);
      let response = await profileService.doesCustomerQualifyForDiscounts(
        this.reservation.customerId,
        customerDiscounts
      );
      if (response.statusCode == "Success" && response.data === true) {
        return response.data;
      }
      return false;
    },
    async getCustomer() {
      this.currentReservation = this.$store.getters["transaction/reservation"];
      const profileService = new AdminProfileService(this.tenantId);
      const customerResponse = await profileService.getCustomerByIdAdmin(
        this.reservation.customerId
      );
      const customer = customerResponse.data;
      const primaryPhone = customer.customerPhoneNumbers.find(x => x.isPrimary);
      this.customer = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        street1: customer.addresses[0].street1,
        street2: customer.addresses[0].street2,
        city: customer.addresses[0].city,
        state: customer.addresses[0].state.trim(),
        zipcode: customer.addresses[0].zipcode,
        mobilePhone: primaryPhone
          ? primaryPhone.number
          : customer.customerPhoneNumbers[0].number,
        email: customer.email,
        comments: customer.comments,
        username: customer.user?.username
      };

      if (!this.reservation.primaryOccupant.mobilePhone) {
        this.reservation.primaryOccupant.mobilePhone = this.customer.mobilePhone;
      }
    },
    async prePageValidation() {
      const spotReservationService = new AdminSpotReservationService(
        this.tenantId
      );
      const fees = await this.getFees();
      const response = await spotReservationService.validateSpotReservationEdit(
        {
          newReservationRequest: {
            spotId: this.reservation.spotId,
            startDate: this.reservation.startDate,
            endDate: this.reservation.endDate,
            lockCode: this.reservation.lockCode,
            discounts: this.reservation.discounts,
            oldSpotId: this.reservation.oldSpotId,
            oldStartDate: this.reservation.oldStartDate,
            oldEndDate: this.reservation.oldEndDate,
            oldDiscounts: this.reservation.discounts,
            customerId: this.reservation.customerId
          },
          reservationId: this.reservation.reservationId,
          feeOverrides: fees
        }
      );
      if (response?.statusCode == "Failure") {
        if (
          this.checkPermission("ReservationManagementOverrideOnBusinessRules")
        ) {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: response.messages[0],
            layer: "admin"
          });
          this.$bvModal.show("override-modal");
        } else {
          this.$store.commit("alert/setErrorQueue", response.messages);
          this.goBack();
        }
      }
    },
    async getFees() {
      const pricingService = new PricingService(this.tenantId);
      const feeResponse = await pricingService.getFees(
        {
          spotId: +this.reservation.spotId,
          oldStartDate: this.reservation.oldStartDate,
          oldEndDate: this.reservation.oldEndDate,
          oldSpotId: this.reservation.oldSpotId,
          oldDiscounts: this.reservation.discounts,
          startDate: this.reservation.startDate,
          endDate: this.reservation.endDate,
          numberOfAdults: this.reservation.numberOfAdults,
          numberOfVehicles: this.reservation.numberOfVehicles,
          discounts: this.reservation.discounts,
          customerId: this.reservation.customerId,
          salesChannel: 2
        },
        2
      );
      return feeResponse.data;
    },
    async getDiscounts() {
      const tenantService = new TenantService(this.tenantId);
      const response = await tenantService.getDiscountClasses();
      this.discountClasses = response.data;
    },
    overrideCancel(overriddenField) {
      this.reservation[overriddenField.propName] = this.currentReservation[
        overriddenField.propName
      ];
    },
    fieldChange(changeObject) {
      if (
        +this.reservation[changeObject.propName] <=
        +this[changeObject.maxAmountProp]
      )
        return;

      this.overriddenField = {
        name: changeObject.fieldName,
        value: this.reservation[changeObject.propName],
        ...changeObject
      };
      this.$bvModal.show("override-field-modal");
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    reservation() {
      const reservation = this.$store.getters["transaction/reservation"];
      reservation.primaryOccupant.state = reservation.primaryOccupant.state.trim();
      reservation.primaryOccupant.zipcode = reservation.primaryOccupant.zipcode.trim();
      reservation.primaryOccupant.mobilePhone = reservation.primaryOccupant
        .mobilePhone
        ? reservation.primaryOccupant.mobilePhone.trim()
        : reservation.primaryOccupant.homePhone
        ? reservation.primaryOccupant.homePhone.trim()
        : "";
      reservation.primaryOccupant.homePhone = reservation.primaryOccupant
        .homePhone
        ? reservation.primaryOccupant.homePhone.trim()
        : "";
      reservation.numberOfVehicles = reservation.numberOfVehicles.toString();
      reservation.equipmentLength = reservation.equipmentLength.toString();
      if (reservation.spot && reservation.spot?.spotEquipmentTypes) {
        var equipmentTypes = reservation.spot.spotEquipmentTypes.map(
          x => x.equipmentType.name
        );
        if (!equipmentTypes.includes(reservation.equipmentType)) {
          reservation.equipmentType = null;
        }
      }
      return reservation;
    },
    basePeople() {
      if (!this.reservation.spot) return null;
      const attr = this.reservation.spot.attributes.find(
        x => x.name === "Minimum Number of People"
      );
      return attr ? attr.value : null;
    },
    maxPeople() {
      if (!this.reservation.spot) return null;
      const attr = this.reservation.spot.attributes.find(
        x => x.name === "Maximum Number of People"
      );
      return attr ? attr.value : null;
    },
    maxVehicles() {
      if (!this.reservation.spot) return null;
      const attr = this.reservation.spot.attributes.find(
        x => x.name === "Maximum Number of Vehicles"
      );
      return attr ? attr.value : null;
    },
    maxVehicleLength() {
      if (!this.reservation.spot) return null;
      const attr = this.reservation.spot.attributes.find(
        x => x.name === "Maximum Vehicle Length"
      );
      return attr ? attr.value : null;
    },
    spotEquipmentTypes() {
      if (!this.reservation.spot) return [];
      return this.reservation.spot.spotEquipmentTypes;
    },
    states() {
      return states;
    },
    isForeignAddress() {
      return this.reservation.primaryOccupant.country !== "US";
    }
  },
  created() {
    this.getCustomer();
    this.getDiscounts();
    this.prePageValidation();
    this.$store.commit("auth/setLoading", false);
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
.summary {
  min-height: 510px;
}
</style>
